import { useState, useEffect } from 'react';
import styles from '../styles/MC.module.css';

const MC_SERVER = process.env.REACT_APP_MC_SERVER;

function MC() {
    const [statusData, setStatusData] = useState(null);
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        fetch("https://api.mcsrvstat.us/2/" + MC_SERVER)
            .then((response) => response.json())
            .then((data) => {
                setStatusData({
                    online: data.online,
                    playersOnline: data.players.online || 0,
                    playersMax: data.players.max || 20,
                    version: data.version,
                    icon: data.icon,
                    motd: data.motd.clean.join(' '),
                });
                setPlayers(data.players.list || []);
            })
            .catch(() => setStatusData({ online: false }));
        }, []);
        
    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <h1>Prominence II RPG: Hasturian Era</h1>
                <h2>v3.1.1</h2>
                <br />
            </header>
            <div className={styles.statusCard}>
                {statusData ? (
                    <>
                        <div className={styles.serverInfo}>
                            <img
                                className={styles.serverImage}
                                src={statusData.icon || 'server-offline.svg'}
                                alt="Server Icon"
                            />
                            <div className={styles.serverDetails}>
                                <p className={styles.motd}>{statusData.motd}</p>
                                <p className={styles.status}>
                                    {statusData.online ? (
                                        <span className={styles.online}>✅ Online</span>
                                    ) : (
                                        <span className={styles.offline}>❌ Offline</span>
                                    )}
                                </p>
                                <p className={styles.version}>Version: {statusData.version}</p>
                                <div className={styles.playerCount}>
                                    <p>
                                        Players: {statusData.playersOnline} / {statusData.playersMax}
                                    </p>
                                    <div className={styles.progressBar}>
                                        <div
                                            className={styles.progress}
                                            style={{
                                                width: `${(statusData.playersOnline / statusData.playersMax) * 100}%`,
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {statusData.online && players.length > 0 && (
                            <div className={styles.playerList}>
                                {players.map((player) => (
                                    <div key={player} className={styles.playerContainer}>
                                        <img
                                            className={styles.playerIcon}
                                            src={`https://minotar.net/avatar/${player}`}
                                            alt={`${player}'s avatar`}
                                        />
                                        <p>{player}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                ) : (
                    <p>Loading server status...</p>
                )}
            </div>
        </div>
    );
}

export default MC;
